import Config from "@/utils/config"


import {
	mapGetters
} from "vuex"

import { getIndexInfo } from "@/api/index/index"
export default {
	name: "index",
	components: {
	},
	data: () => {
		return {
			timer:null,
			loadingAd: true,
			loadingFloor: true,
			isMshow:false,
			isFshow:false,
			isPshow:false,
			isPshow01:false,
			isPshow11:false,
			isPshow21:false,
			isPshow31:false,
			isPshow41:false,
			isIndex:'',			
			info:{},
			isComShow:false,
			deviceType:{},
			authInfo:[],
			UserInfo:[],
			projectData:'',
			config: {
				data: [30],
				shape: "round",
				colors: ["#43ff44", "#43ff44"],
				waveOpacity: "1",
				waveNum: "50",
				formatter: "{value}",
			  },
			
		}
	},
	watch: {
	},
	created() {
		this.projectData = JSON.parse(localStorage.getItem("deviceType"))
		this.authInfo = JSON.parse(localStorage.getItem("autharr"))
		this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"))
	},
	mounted() {
		window.addEventListener("scroll", this.handleScroll)
		
	},
	computed: {
	},
	methods: {
		// 打开先控多窗口
		toDetail(key,name,nodename,Aname,titname){
			if(!this.authInfo[1]){
				let str = this.authInfo[0]
				// 判断是否为调试还是查看权限
				if (str.charAt(str.length-1) == "r") {
					this.$message.error('暂无调试权限');
				}
			}else{
				let pathname=""
				switch(key){
					case 1:
					pathname = 'firstcontol'
					break
					case 2:
				    pathname = 'rqkfirstcontol'
					break
					case 3:
				    pathname = 'rqRfirstcontol'
					break
				}
				const newurl = this.$router.resolve({name: pathname, query: {Firstcontrolname: name,Firstcontrolnode:nodename,Firstcontroltitname:titname}})
				window.open(newurl.href,'_blank')
			}
		},
		// 打开手操器组件
		toCompon(key,name,nodename,Aname,titname,param1,param2,param3){
			if(!this.authInfo[1]){
				let str = this.authInfo[0]
				// 判断是否为调试还是查看权限
				if (str.charAt(str.length-1) == "r") {
					this.$message.error('暂无调试权限');
				}
			}else{
			this.isIndex = key
			this.Aname = Aname
			this.historyname = name,
			this.nodename = nodename
			clearTimeout(this.grouptime);	
			this.grouptime=setTimeout(() =>{
				this.maskbok = true
			switch(key){
				case 0:
					return this.isMshow = true,this.Manualname = name,this.Manualnode = nodename,
					this.ManualAname = Aname,this.Manualtitname = titname,this.Panualmark = param1,this.Panualnode = param2,this.Panualnodem = param3
					case 1 :
					return  this.isFshow = true,this.Firstcontrolname = name,this.Firstcontrolnode = nodename,this.Firstcontroltitname = titname 
					case 2:
					return 	this.isHshow = true,this.chName = titname,this.maskbok = false	
					case 3:
					return  this.Param05 = true
					case 4:
					return  this.isRshow = true,this.Rsfname = name,this.Rsfnode = nodename 
					case 5:
					return  this.isPshow = true
					case 6:
					return  this.Param01 = true
					case 7:
					return  this.Param02 = true
					case 8:
					return  this.Param03 = true
					case 9:
					return  this.Param04 = true
					case 10:
					return  this.Param041 = true
			}
		},300);
		}
	},
		isClose(val,val1){
			this.maskbok = false
			switch(val){
				case 'Manual':
					return this.isMshow = val1
					case 'Evaluate' :
					case (this.flag == 2):	
					return  this.flag = 0,this.$bus.$emit("footersendMsg", 0);
					case 'RqOnlineService' :
					case (this.flag == 7):	
					return  this.flag = 0,this.$bus.$emit("footersendMsg", 0)
					case 'RqSoftNumber' :
					case (this.flag == 6):	
					return  this.flag = 0,this.$bus.$emit("footersendMsg", 0)
					case 'RqCommonParameters':
					case (this.flag == 4):	
					return  this.flag = 0,this.$bus.$emit("footersendMsg", 0)
					case 'Historical':
					return this.isHshow = val1
					case 'Param05':
					return this.Param05 = val1	
					case 'Rsf':
					return this.isRshow = val1	
					case 'param':
					return this.isPshow = val1	
					case 'Param01':
					return this.Param01 = val1
					case 'Param03':
					return this.Param03 = val1
					case 'Param02':
					return this.Param02 = val1
					case 'Param041':
					return this.Param041 = val1
					case 'Param04':
					return this.Param04 = val1
					case 'JrSecurity':
					return this.JrSecurity = val1
					case 'Jrconst':
					return this.Jrconst = val1
				
			}
			// if(val = 'Manual'){
			// 	this.isMshow = val1
			// }
		},
		// 监听滚动条
		handleScroll() {
			var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop

			if (scrollTop >= 680) {
				this.isShow = true
			} else {
				this.isShow = false
			}
		},
		destroyed() {
			// 离开该页面需要移除这个监听的事件，不然会报错
			window.removeEventListener("scroll", this.handleScroll)
		},

	},

}
